import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Splide from '@splidejs/splide';
import LazyLoad from "vanilla-lazyload";
import decodeBlurHash from 'fast-blurhash';

// --------------------------------------------------
// 🪗 Accordion
// --------------------------------------------------

const accordions = document.querySelectorAll('.accordion');

const toggleAccordion = (accordionButton) => {

  var parent = accordionButton.parentElement;

  if (parent.classList.contains('accordion-group')) {
      console.log('The parent has class "group"');
      // Close other accordion items
      // accordions.forEach(otherAccordion => {
      //   const otherAccordionButton = otherAccordion.querySelector('button');
      //   if(otherAccordionButton !== accordionButton) {
      //     otherAccordionButton.setAttribute('aria-expanded', 'false');
      //   }
      // });
  }

  // Function to add a class after a delay
  function addClassAfterDelay() {
    setTimeout(() => {
      parent.classList.add("close-text-container");
    }, 200); // 200 milliseconds = 0.2 seconds
  }

  // Function to add a class after a delay
  function removeClassAfterDelay() {
    setTimeout(() => {
      parent.classList.remove("close-text-container");
    }, 200); // 200 milliseconds = 0.2 seconds
  }
  
  // Expand the accordion
  if(accordionButton.getAttribute('aria-expanded') !== 'true') {
    accordionButton.setAttribute('aria-expanded', 'true');
    parent.classList.add('accordion--open');
    addClassAfterDelay();
  }
  // Close the accordion
  else {
    accordionButton.setAttribute('aria-expanded', 'false');
    parent.classList.remove('accordion--open');
    removeClassAfterDelay();
  }

}

accordions.forEach(accordion => {

  // Use querySelectorAll to select all button elements within the parent element
  const allButtons = accordion.querySelectorAll('button');

  // Loop through the buttons to find the first direct child button
  let firstChildButton = null;
  for (const button of allButtons) {
    if (button.parentElement === accordion) {
      firstChildButton = button;
      break; // Found the first direct child button, exit the loop
    }
  }

  firstChildButton.addEventListener('click', () => {
    toggleAccordion(firstChildButton);
  })
})

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    gsap.to(el, {
      autoAlpha: 1,
      duration: .5,
      ease: 'power1.inOut',
      onComplete: () => {
        el.dispatchEvent(new Event('lazyloaded'));
      }
    })
  }
});

// --------------------------------------------------
// Changing title
// --------------------------------------------------

// Get the reference to the div with class "title"
const titleDiv = document.querySelector('.schedule__title');

// Store the initial word
const initialWord = titleDiv.textContent;

// Function to randomly change a character within the word
function randomlyChangeCharacter() {
const wordArray = initialWord.split('');
const randomIndex = Math.floor(Math.random() * wordArray.length);
const randomChar = String.fromCharCode(97 + Math.floor(Math.random() * 26)); // Generates a random lowercase letter

// Replace a character with a random one
wordArray[randomIndex] = randomChar;

// Update the content of the div
titleDiv.textContent = wordArray.join('');

// Generate a random delay between 500ms and 3000ms (adjust as needed)
const randomDelay = Math.floor(Math.random() * 2500) + 500;

// Call randomlyChangeCharacter again after the random delay
setTimeout(randomlyChangeCharacter, randomDelay);
}

// Call randomlyChangeCharacter initially
randomlyChangeCharacter();

// --------------------------------------------------
// 👩🏻‍🦳 Navbar scroll animation
// --------------------------------------------------

// When the user scrolls down 50px from the top of the document, slide down the navbar
window.onscroll = function() {
  if (window.innerWidth < 850) {
    scrollFunction()
  }
};

function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-43px";
  }
}


// --------------------------------------------------
// 🎠 Carousels
// --------------------------------------------------

let carousel = document.querySelector('.splide');
// Iterate through all elements with class 'entries__embed-video-container'
let vimeoVideos = document.querySelectorAll('.entries__embed-video-container');
// Create a Vimeo Player for each video and store them in an array
let players = [];
vimeoVideos.forEach(function (video) {
  let player = new Vimeo.Player(video);

  // Add the player to the players array
  players.push(player);
});

console.log(vimeoVideos)
// Pause all Vimeo videos
function pauseAllVideos() {
  players.forEach(function (player) {
    player.pause();
  });
}

let splide = new Splide(carousel, {
  type: 'fade',
  rewind: true,
  speed: 0,
  flickPower: 300,
  arrows: false,
  pagination: false
});

splide.mount();

// Getting date and caption

// Listen for slide change events
splide.on('moved', function (newIndex) {
  // Get the caption of the current slide
  var currentSlide = splide.Components.Elements.slides[newIndex];
  var captionElement = currentSlide.querySelector('.entries__carousel-cell-caption');
  var captionText = captionElement.innerHTML;
  var captionTextContainer = document.querySelector('.entries__caption-container');

  captionTextContainer.innerHTML = captionText;

  // Call the pauseAllVideos function to pause all videos
  pauseAllVideos();

});


// Counter update
let carouselCounter = document.querySelector('.carousel-counter');
if(carouselCounter) {

  let counterStatuses = document.querySelectorAll('.carousel-counter__status');

  const updateCounter = () => {
    counterStatuses.forEach((counterStatus) => {
      counterStatus.innerHTML = splide.index + 1;
    })
  }

  splide.on('move', (e) => {
    updateCounter();
  })

}

// Click to change slide
if(carousel.classList.contains('carousel--click-to-next')) {
  let nextButtons = document.querySelectorAll('.carousel');
  if(nextButtons) {
    nextButtons.forEach((item) => {
      // Get the width of the div
      const divWidth = item.clientWidth;
      item.addEventListener( 'click', function(event) {
        
        // Get the horizontal position of the click event
        var clickX = event.clientX - item.getBoundingClientRect().left;
        
        // Calculate the distance from the left and right edges
        var distanceToLeft = clickX;
        var distanceToRight = divWidth - clickX;
        
        if (distanceToLeft < distanceToRight) {
          splide.go('<');
        } else {
          splide.go('>');
        }
      })
      item.addEventListener('mousemove', function(event) {
        
        // Get the horizontal position of the cursor within the div
        var cursorX = event.clientX - this.getBoundingClientRect().left;
        
        // Calculate the distance from the cursor to the left and right edges
        var distanceToLeft = cursorX;
        var distanceToRight = divWidth - cursorX;
        
        if (distanceToLeft < distanceToRight) {
            item.setAttribute('data-mouse', 'left');
        } else {
            item.setAttribute('data-mouse', 'right');
        }
      });
    });
  };

}